var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"close-chat-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$t(_vm.module.model.descriptionKey),"tags":_vm.tariffTag},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),(!_vm.tariffTag)?_c('save-module-button',{on:{"save-module":_vm.saveModule}}):_vm._e()]},proxy:true}])}),_c('a-card',[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'closeChatTypeVm',
          'options': _vm.closeChatBanTypeOptions,
          'multiple': false,
          'clearable': false,
          'tariffTags': _vm.tariffTag,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'nativeClose',
          'hasAnchor': true,
          'tags': _vm.EXPERIMENTAL_TAG,
          'tariffTags': _vm.tariffTag,
        }
      }}}),_c('config-field',{staticClass:"close-chat-time-wrapper",attrs:{"title":_vm.$t('field_close_chat_interval_from_to_title'),"mini-help-message":_vm.$t('field_close_chat_interval_from_to_mini_help_message'),"tags":_vm.tariffTag,"disabled":!!_vm.tariffTag,"has-anchor":true}},[_c('div',{staticClass:"items-end flex gap-2"},[_c('time-picker-input',{staticClass:"flex-1 mb-0 pb-0 mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params.close_chat_interval,
              'key': 'from',
              'clearable': false,
              'time24hr': true,
              'titleVisible': false,
              'disabled': !!_vm.tariffTag
            }
          }}}),_c('time-picker-input',{staticClass:"flex-1 mb-0 pb-0 mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params.close_chat_interval,
              'key': 'to',
              'clearable': false,
              'time24hr': true,
              'titleVisible': false,
              'disabled': !!_vm.tariffTag
            }
          }}}),(_vm.isCloseChatIntervalSet)?_c('a-button',{attrs:{"type":"danger","icon":"delete","disabled":!!_vm.tariffTag},on:{"click":_vm.resetInterval}}):_vm._e()],1),_c('timezone-select')],1),_c('week-day-picker-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': this,
          'key': 'daysDontOpen',
          'hasAnchor': true,
          'tags': _vm.EXPERIMENTAL_TAG,
          'tariffTags': _vm.getTagsByFieldKey('before_close_chat_message'),
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'open_chat_message',
          'targetToUpload': _vm.groupUploadTarget,
          'tariffTags': _vm.tariffTag,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'close_chat_message',
          'targetToUpload': _vm.groupUploadTarget,
          'tariffTags': _vm.tariffTag,
          'tags': _vm.EXPERIMENTAL_TAG,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true
        },
      }}}),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'before_close_chat_message_interval',
          'min': 0,
          'settingWrapper': {
            customiseValue: _vm.StepUnit.Hour,
            hasFieldButton: true,
            disabledValue: 0
          },
          'tariffTags': _vm.getTagsByFieldKey('before_close_chat_message'),
          'tags': _vm.EXPERIMENTAL_TAG
        }
      }}}),(_vm.module.model.params.before_close_chat_message_interval > 0)?_c('nested-content',{staticClass:"mb-0"},[_c('multi-message-editor-with-media-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'before_close_chat_message',
            'targetToUpload': _vm.groupUploadTarget,
            'tags': _vm.EXPERIMENTAL_TAG,
            'tariffTags': _vm.getTagsByFieldKey('before_close_chat_message'),
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
            'hasMedia': true
          },
        }}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }